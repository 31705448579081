<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="vertical-align: middle;">
        <path
            d="M10 18C12.2091 18 14 16.2091 14 14C14 11.7909 12.2091 10 10 10C7.79086 10 6 11.7909 6 14C6 16.2091 7.79086 18 10 18Z"
            stroke="#0053FF"
            stroke-width="2"
        />
        <path d="M13 11L17 7" stroke="#0053FF" stroke-width="2" />
        <path d="M18 10V6H14" stroke="#0053FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class MaleIcon extends Vue {}
</script>